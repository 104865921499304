.autocomplete {
  background: white;
  z-index: 1000;
  overflow: auto;
  box-sizing: border-box;
  border: 1px solid rgba(50, 50, 50, 0.6);
}

.autocomplete > div {
  padding: 5px;
}

.autocomplete > div:hover,
.autocomplete > div.selected:not(:first-child) {
  background: #81ca91;
  cursor: pointer;
}
